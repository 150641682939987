.content {
  color: var(--app-text-color);
  // max-width: 960px;
  margin: 50px auto 184px;

  > .topContent {
    text-align: center;

    > .title {
      font-family: Inter, Inter;
      font-weight: bold;
      font-size: 3rem;
      color: #151B26;
      line-height: 1;
      letter-spacing: 2px;
      text-align: center;
      font-style: normal;
      text-transform: none;

      @media screen and (max-width: 768px) {
        font-size: 2.5rem;
      }
    }

    >.title span {
      opacity: 0;
      display: inline-block;
      animation: fadeIn 0.5s forwards;
      color: #1E5EFF;
    }
    
    @keyframes fadeIn {
      to {
        opacity: 1;
      }
    }

    > .desc {
      margin-top: 2.5rem;
      font-family: Inter, Inter;
      font-weight: 500;
      font-size: 24px;
      color: #5A607F;
      line-height: 34px;
      text-align: center;
      font-style: normal;
      text-transform: none;

      @media screen and (max-width: 768px) {
        font-size: 20px;
      }
    }

    > .desc1 {
      margin-top: 2rem;
      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #7E84A3;
      line-height: 22px;
      font-style: normal;
      text-transform: none;
    }

    > .valContainer {
      margin-top: 1rem;
      display: flex;
      align-items: first baseline;
      justify-content: center;
      gap: 8px;

      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #7E84A3;
      line-height: 22px;
      text-align: left;
      font-style: normal;
      text-transform: none;

      > .valCount {
        font-family: Inter, Inter;
        font-weight: 700;
        font-size: 48px;
        color: #1E5EFF;
        line-height: 67px;
        text-align: left;
        font-style: italic; // Changed from normal to italic for a slight slant
        text-transform: none;

        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
      }
    }

    > p {
      margin-top: 24px;

      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 16px;
      color: #5a607f;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }

    .dashboard {
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      margin-top: 28px;
      height: 40px;
      background: linear-gradient(270deg, #7141f9 0%, #1e85ff 100%);
      border-radius: 6px;

      font-family: Inter, Inter;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      text-transform: none;

      padding: 12px 20px;
      min-width: 160px;
    }

    .searchContainer {
      margin: 0 auto;
      margin-top: 28px;
      width: 521px;
      height: 48px;
      background: #ffffff;
      border-radius: 6px 6px 6px 6px;
      border: 1px solid #dfe2ee;
      display: flex;
      gap: 5px;
      align-items: center;
      padding: 5px;

      @media screen and (max-width: 768px) {
        width: 350px;
      }

      .searchIcon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 26px;
        height: 26px;
        border-radius: 6px 6px 6px 6px;
      }

      .carouselContainer {
        width: 100%;
        height: 100%;

        // display: inline-block;

        .carouselItem {
          height: 40px;
          line-height: 40px;
        }

        .inputContainer {
          width: 100%;
          height: 100%;
          background: #ffffff;
          border: none;
          color: var(--app-text-color);
          font-size: 16px;

          &:focus {
            border: none; /* 移除边框 */
            outline: none; /* 确保没有外框 */
          }
          &::placeholder {
            color: #bac3d0;
          }
        }
      }

      .go {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        width: 84px;
        height: 36px;
        background: linear-gradient(270deg, #7141f9 0%, #1e85ff 100%);
        border-radius: 6px 6px 6px 6px;

        font-family: Inter, Inter;
        font-weight: 500;
        font-size: 14px;
        color: #ffffff;
        line-height: 16px;
        font-style: normal;
        text-transform: none;
        cursor: pointer;
      }
    }
    

    // > .videoContent {
    //   flex: 4;
    //   box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
    //   border-radius: 12px;
    //   border: 4px solid rgba(255, 255, 255, 0.8);
    //   :global {
    //     .yt-lite {
    //       border-radius: 12px;
    //       border: 1px solid rgba(0, 0, 0, 0.1);
    //     }
    //   }
    // }
  }

  > .bottomContent {
    margin-top: 90px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.25rem;

    > .listItem {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 20px;

      background: #ffffff;
      box-shadow: 0px 2px 16px 0px rgba(47, 91, 157, 0.15);
      border-radius: 12px;

      font-family: Inter, Inter;
      font-weight: normal;
      font-size: 16px;
      color: #151b26;
      line-height: 19px;
      text-align: left;
      font-style: normal;
      text-transform: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .content {

    // > .topContent {
    //   > .videoContent {
    //     display: none;
    //   }
    // }

    .list {
      grid-template-columns: repeat(2, 1fr);

      .listItem {
        justify-content: flex-start;
      }
    }
    .btnContainer {
      flex-direction: column-reverse;
    }
  }
}

@media screen and (min-width: 960px) {
  .content {
    margin-top: 50px;
    margin-bottom: 200px;

    > h1 {
      font-size: 3rem;
      margin-top: 1.75rem;
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 960px) and (min-width: 768px) {
  .content {
    margin-top: 40px;
    margin-bottom: 154px;

    > .bottomContent {
      margin-top: 70px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: 769px) and (min-width: 576px) {
  .content {
    margin-top: 30px;
    margin-bottom: 119px;

    > .bottomContent {
      margin-top: 50px;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 576px) {
  .content {
    margin-top: 10px;
    margin-bottom: 50px;

    > h1 {
      font-size: 2rem;
      margin-top: 0;
      margin-bottom: 20px;

      .tracker {
        font-size: 1.5rem;
      }
    }

    > p {
      font-size: 18px;
      margin-bottom: 2rem;
    }

    .btnContainer {
      margin-top: 30px;
    }

    > .bottomContent {
      margin-top: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

